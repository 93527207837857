import {api} from '~/ViewInvitation/api/api';
import {reducer as moduleOrder} from './moduleOrder';
import {reducer as eviteEvent} from './eviteEvent';
import {reducer as eventDetails} from './eventDetails';
import {reducer as whoIsComing} from './whoIsComing';
import {reducer as gifting} from './gifting';
import {reducer as whatToBringSummary} from './whatToBringSummary';
import {reducer as whatToBring} from './whatToBring';
import {reducer as donation} from './donation';
import {reducer as poll} from './poll';
import {reducer as user} from './user';
import {reducer as guestList} from './guestList';
import {reducer as avatars} from './avatars';
import {reducer as rsvp} from './rsvp';
import {reducer as coverData} from './coverData';
import {reducer as registry} from './registry';
import {reducer as photoSummary} from './photoSummary';
import {reducer as photoPosts} from './photoPosts';
import {reducer as photos} from './photos';
import {reducer as posts} from './posts';
import {reducer as newMessages} from './newMessages';
import {reducer as giftKeywords} from '~/common/gift-keywords/reducer';
import {reducer as premiumDesign} from './premiumDesign';
import {reducer as fabricDesign} from './fabricDesign';
import {reducer as loginModal} from '~library/organisms/LoginModal/reducer';

export const rootReducer = {
  [api.reducerPath]: api.reducer,
  moduleOrder,
  eviteEvent,
  eventDetails,
  whoIsComing,
  gifting,
  whatToBringSummary,
  whatToBring,
  donation,
  poll,
  user,
  guestList,
  photoPosts,
  photoSummary,
  photos,
  posts,
  avatars,
  rsvp,
  coverData,
  registry,
  newMessages,
  giftKeywords,
  premiumDesign,
  fabricDesign,
  loginModal,
};
