import {smallAndUp} from '~libSass/base/_exports.sass';

export const useEmojiBurstAnimation = (rsvpDrawer) => {
  const animMatrix = [
    {x: -80, y: -270, ang: -15, scale: 1.3},
    {x: -105, y: -160, ang: -20, scale: 0.8},
    {x: -57, y: -70, ang: -15, scale: 1},
    {x: -3, y: -325, ang: 3, scale: 1.2},
    {x: 30, y: -204, ang: -2, scale: 1.3},
    {x: -30, y: -154, ang: 5, scale: 1},
    {x: 5, y: -23, ang: 3, scale: 1},
    {x: 80, y: -270, ang: 15, scale: 0.8},
    {x: 105, y: -160, ang: 19, scale: 1.2},
    {x: 47, y: -76, ang: 15, scale: 1.3},
  ];

  const animate = (event, reaction) =>
    new Promise((resolve) => {
      const {matches: largeScreens} = window.matchMedia(`(min-width: ${smallAndUp})`);
      const leftReference = largeScreens ? event.target : document.getElementById('emoji-party');
      const left = leftReference.getBoundingClientRect().left + 25;
      const top = event.clientY - 32;

      // Create 10 emojis and then give them new css values to animate to
      for (let i = 0; i < 10; i++) {
        const newElement = document.createElement('div');
        newElement.style.cssText = `
          left: ${left}px;
          top: ${top}px;
          transform: rotate(${animMatrix[i].ang}deg) scale(${animMatrix[i].scale})
        `;
        newElement.innerHTML = reaction;
        newElement.id = `emoji-anim-${i}`;
        document.body.appendChild(newElement);
        window.setTimeout(() => {
          const el = document.getElementById(`emoji-anim-${i}`);
          el.style.left = `${left + animMatrix[i].x}px`;
          el.style.top = `${top + animMatrix[i].y}px`;
          el.style.opacity = 0;
        }, 50);
      }

      if (rsvpDrawer?.current) {
        rsvpDrawer.current.style.transition = 'all 1.5s';
        rsvpDrawer.current.classList.remove('open');
      }

      // Remove emojis from body
      window.setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          document.getElementById(`emoji-anim-${i}`).remove();
        }
        resolve();
      }, 3100);
    });

  return {animate};
};
