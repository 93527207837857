export const trackSubmitedRSVP = (response) => {
  if (window.tags)
    window.tags.track_event('submit_rsvp', {
      invite_rsvp_response: response,
    });
};

export const trackInviteRSVP = (response, optin) => {
  if (window.dataLayer)
    window.dataLayer.push({
      event: 'invite_rsvp',
      invite_rsvp_response: response,
      invite_rsvp_optin: optin,
    });
  window.BOOMR.hit('eventRSVP');
};

export const trackPollVote = (pollEvent) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: pollEvent,
    });
  }
};
