export const logChangeDesign = (ecardId: string, templateId: string) => {
  window.dataLayer.push({
    event: 'change_card_design',
    card_id: ecardId || null,
    template_id: templateId,
  });
};

export const logChangeReveal = (ecardId: string, revealId: string) => {
  window.dataLayer.push({
    event: 'change_card_reveal',
    card_id: ecardId || null,
    reveal_id: revealId,
  });
};

export const logChangeEffect = (ecardId: string, overlayId: string) => {
  window.dataLayer.push({
    event: 'change_card_effect',
    card_id: ecardId || null,
    overlay_id: overlayId,
  });
};

export const logDesignStepPreviewClick = (ecardId: string) => {
  window.dataLayer.push({
    event: 'card_design_step_open_preview',
    card_id: ecardId || null,
  });
};

export const logGiftCardsStepPreviewClick = (ecardId: string) => {
  window.dataLayer.push({
    event: 'card_gift_cards_step_open_preview',
    card_id: ecardId,
  });
};

export const logRecipientMessagesStepPreviewClick = (ecardId: string) => {
  window.dataLayer.push({
    event: 'card_recipient_messages_step_open_preview',
    card_id: ecardId,
  });
};

export const logAddGlobalMessage = (ecardId: string) => {
  window.dataLayer.push({
    event: 'add_global_card_message',
    card_id: ecardId,
  });
};

export const logAddCustomMessage = (ecardId: string) => {
  window.dataLayer.push({
    event: 'add_custom_card_message',
    card_id: ecardId,
  });
};

export const logSelectSendCardNow = (ecardId: string) => {
  window.dataLayer.push({
    event: 'select_send_card_now',
    card_id: ecardId,
  });
};

export const logSelectSendCardLater = (ecardId: string) => {
  window.dataLayer.push({
    event: 'select_send_card_later',
    card_id: ecardId,
  });
};

export const logReactToCard = (ecardId: string, guestId: string, reaction: string) => {
  window.dataLayer.push({
    event: 'react_to_card',
    card_id: ecardId,
    guest_id: guestId,
    reaction,
  });
};
