import {Mutex} from 'async-mutex';
import {showCaptcha} from '~/common/auth/utils';

export const customBaseQuery = async ({url = '', method, body, params}) => {
  try {
    const response = await evite.fetch(window.location.origin + url, {
      method,
      credentials: 'include',
      headers: {
        'X-CSRFToken': evite.cookie('csrftoken'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: typeof body === 'string' ? body : JSON.stringify(body),
      params,
    });
    const data = await response.json();
    return response.ok ? {data} : {error: {status: response.status, data}};
  } catch (error) {
    return {
      error: {
        status: 500,
        data: error,
      },
    };
  }
};

const mutex = new Mutex();

export const customBaseQueryWithCaptcha = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result;
  let attempts = 0;
  let captchaResponse = null;
  // Make API call, aware of captcha retries
  while (attempts < 3) {
    attempts++;
    const queryArgs = {
      ...args,
    };
    if (captchaResponse) {
      queryArgs.body = queryArgs?.body || {};
      queryArgs.body.captcha = captchaResponse;
    }
    // eslint-disable-next-line no-await-in-loop
    result = await customBaseQuery(queryArgs, api, extraOptions);
    if (!result.error) break; // No error
    switch (result.error.status) {
      case 412: {
        // Handle captcha, and do not auto-return (i.e. allow retry)
        const payload = result.error.data?.error?.data?.detail || result.error.data;
        // eslint-disable-next-line no-await-in-loop
        captchaResponse = await showCaptcha({
          ...result.error,
          data: payload,
        });
        if (attempts >= 3) {
          return result;
        }
        break;
      }
      default: {
        return result;
      }
    }
  }
  return result;
};
