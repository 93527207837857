import React from 'react';
import {Typography} from '~library/atoms/Typography';
import {ArrowIcon} from '~library/svg/ArrowIcon';

export const MoreDetailsIndicator = ({preview}) => (
  <div
    className={`design-template__more-details-flex-wrapper ${!preview ? 'invitation-margin' : ''}`}
  >
    <Typography
      className="design-template__more-details"
      data-qa-id="more_details_icon"
      variant="label2"
    >
      MORE DETAILS
    </Typography>
    <ArrowIcon className="design-template__more-details-arrow" ratio={0.39} direction="down" />
  </div>
);
