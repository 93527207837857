import {SUBMIT_RSVP} from './constants';

const INIT_FROM_CLIENT_DATA = 'ViewInvitation/user/initUser';

const initialState = {
  userId: '',
  guestId: window.guest_id || '',
  name: '',
  email: '',
  isHost: false,
  isRegistered: false,
};

export function initUser(viewingUser) {
  return {
    type: INIT_FROM_CLIENT_DATA,
    viewingUser,
  };
}

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_FROM_CLIENT_DATA:
      return {
        ...state,
        ...action.viewingUser,
        guestId: state.guestId || action.viewingUser?.guestId || '',
      };
    case SUBMIT_RSVP: {
      const {guest} = action.results;
      return {
        ...state,
        guestId: guest.id,
        userId: guest.user_id,
        // The RSVP response's 'host' value is not reliable so we
        // keep the existing isHost flag unless the guestId changes.
        isHost: state.guestId === guest.id ? state.isHost : false,
        name: guest.name,
        email: guest.email,
        isRegistered: action.results.is_registered_user,
      };
    }
    default:
      return state;
  }
}
