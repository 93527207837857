import {
  ADD_FAILED_PHOTO,
  MARK_UPLOAD_UPLOADING,
  ADD_PENDING_PHOTO,
  CLEAR_PENDING_PHOTOS,
  CONVERT_TO_FAILED,
  MARK_UPLOAD_SUCCESS,
  PHOTO_STATUS,
} from './constants';

const initialState = {
  photos: [],
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_PENDING_PHOTO: {
      return {
        ...state,
        photos: [
          ...state.photos,
          {
            id: action.payload.id,
            file: action.payload.file,
            status: PHOTO_STATUS.PENDING,
          },
        ],
      };
    }
    case ADD_FAILED_PHOTO: {
      return {
        ...state,
        photos: [
          {
            id: action.payload.id,
            file: action.payload.file,
            reason: action.payload.reason,
            status: PHOTO_STATUS.ERROR,
          },
          ...state.photos,
        ],
      };
    }
    case CLEAR_PENDING_PHOTOS: {
      return {
        ...state,
        photos: [],
      };
    }

    case CONVERT_TO_FAILED: {
      const photos = state.photos.map((p) =>
        p.id === action.payload.id
          ? {
              ...p,
              reason: action.payload.reason,
              status: PHOTO_STATUS.ERROR,
            }
          : p
      );

      return {
        ...state,
        photos,
      };
    }
    case MARK_UPLOAD_SUCCESS: {
      const photos = state.photos.map((p) =>
        p.id === action.payload.id
          ? {
              ...p,
              photoId: action.payload.photoId,
              status: PHOTO_STATUS.SUCCESS,
            }
          : p
      );
      return {
        ...state,
        photos,
      };
    }
    case MARK_UPLOAD_UPLOADING: {
      const photos = state.photos.map((p) =>
        p.id === action.payload.id
          ? {
              ...p,
              status: PHOTO_STATUS.UPLOADING,
            }
          : p
      );
      return {
        ...state,
        photos,
      };
    }
    default:
      return state;
  }
}
