// Should align with BE's core/events/constants.py so we maintain a complete list

export const ORIGIN_DICT = {
  AG: 3, // American Greeting
  CEC: 4, // Chuck E. Cheese
  CMM: 5,
  AOLMAIL: 6,
  VISAFT: 7,
  YMAIL: 8,
  EVT: 10, // Old/original evite.com java site
  NEO: 11, // Current core site
  EVITE: 11, // Current core site
  SPD: 11, // Failed evite rewrite 'spd' aka 'speed'
  IOS: 12, // iPhone Core App
  POSTMARK: 13, // Legacy premium
  // Plans / Evite Me / Evite Go / New Mobile App
  // NOTE none of these events should be loaded into the new system.
  PLANS: 14,
  ANDROID: 15, // Android
  GENESIS: 16, // Rudy's new mobile web
  IOS2: 17, // updated iPhone Core App
  ANDROID2: 18, // updated iPhone Core App
  GIFTING: 19, // Lars' Photo Gifting App
  GREETINGCARD2: 20, // Greeting Card 2.0
  FABRIC: 21, // Fabric Invitation
  FABRICGREETINGCARD: 22, // Fabric Greeting Card
  QUICKECARD: 23, // Quick ECard
};

// please add any groupings below if these don't meet future needs
export const CARD_ORIGINS = [
  ORIGIN_DICT.FABRICGREETINGCARD,
  ORIGIN_DICT.GREETINGCARD2,
  ORIGIN_DICT.QUICKECARD,
];
export const FABRIC_EVENT_ORIGINS = [ORIGIN_DICT.FABRIC];
export const FABRIC_GRC_ORIGINS = [ORIGIN_DICT.FABRICGREETINGCARD, ORIGIN_DICT.QUICKECARD];
export const LEGACY_GRC_ORIGINS = [ORIGIN_DICT.GREETINGCARD2];
export const LEGACY_PREMIUM_ORIGINS = [ORIGIN_DICT.POSTMARK];
